import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import "./Title.scss";

const Title = ({ text, delay = 0, className, animated = true }) => {
    if (!animated) {
         return (
             <div className={`cfp-title ${className}`} delay={delay}>
                 {text}
             </div>
         );
    }
    return (<ScrollAnimation 
            animateIn="fadeIn" 
            animateOnce={true} 
            className={`cfp-title ${className}`}
            delay={delay}>
            {text}
        </ScrollAnimation>)
};

export default Title;
