import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import './Question.scss';

const Question = ({ pregunta, respuesta, index }) => {
    const [collapsed, setCollapse] = useState(true);

    const toggleCollapse = () => {
        setCollapse(!collapsed);
    };
    const showIcon = collapsed => (collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />);
    const { pregunta: preg } = pregunta;
    const { childMarkdownRemark: resp } = respuesta;
    return (
        <div className="question-container">
            <div
                className="question"
                onClick={() => toggleCollapse()}
                onKeyPress={() => toggleCollapse()}
                role="button"
                tabIndex={0}
            >
                <p dangerouslySetInnerHTML={{ __html: preg }} />
                <span className="icon">{showIcon(collapsed)}</span>
            </div>
            {!collapsed && (
                <div className="answer text" dangerouslySetInnerHTML={{ __html: resp.html }} />
            )}
        </div>
    );
};

export default Question;
