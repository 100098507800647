import React from 'react';
import { graphql } from 'gatsby';
import ScrollAnimation from 'react-animate-on-scroll';
import Layout from '../components/Layout/Layout';
import Title from '../UI/Title';
import Question from '../components/Question/Question';
import './scss/faqs.scss';

const Preguntas = ({ data, ...props }) => {
    const preguntas = data.allContentfulPreguntasEnLinea;
    const preguntasPres = data.allContentfulPreguntasPresencial;
    const preguntasVar = data.allContentfulPreguntasVariadas;
    const questions = preguntas.edges || {};
    const questions2 = preguntasPres.edges || {};
    const questions3 = preguntasVar.edges || {};
    return (
        <Layout title="Preguntas" {...props}>
            <section className="questions gray">
                <div className="child">
                    <div className="cfp-container inner">
                        <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={600}>
                            <div className="questions-list">
                                <Title text="CONSULTA ONLINE" delay={100} className="smaller" animated={false} />
                                {questions &&
                                    questions.map(({ node }, index) => {
                                        return <Question key={index} index={index} {...node} />;
                                    })}
                            </div>
                            <div className="questions-list">
                                <Title text="CONSULTA PRESENCIAL" delay={100} className="smaller" animated={false} />
                                {questions2 &&
                                    questions2.map(({ node }, index) => {
                                        return <Question key={index} index={index} {...node} />;
                                    })}
                            </div>
                            <div className="questions-list">
                                <Title text="VARIADAS" delay={100} className="smaller" animated={false} />
                                {questions3 &&
                                    questions3.map(({ node }, index) => {
                                        return <Question key={index} index={index} {...node} />;
                                    })}
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="rectangle-left">
                    <ScrollAnimation animateIn="slideInLeft" animateOnce={true}>
                        <img src="/rectangle.svg" alt="Rectangle" className="rectangle-img" />
                    </ScrollAnimation>
                </div>
            </section>
        </Layout>
    );
};

export const query = graphql`
           query MyQuery {
               allContentfulPreguntasEnLinea(sort: { fields: [posicion, createdAt], order: ASC }) {
                   edges {
                       node {
                           pregunta {
                               pregunta
                           }
                           respuesta {
                               childMarkdownRemark {
                                   html
                               }
                           }
                       }
                   }
               }
               allContentfulPreguntasPresencial(sort: { fields: [posicion, createdAt], order: ASC }) {
                   edges {
                       node {
                           pregunta {
                               pregunta
                           }
                           respuesta {
                               childMarkdownRemark {
                                   html
                               }
                           }
                       }
                   }
               }
               allContentfulPreguntasVariadas(sort: { fields: [posicion, createdAt], order: ASC }) {
                   edges {
                       node {
                           pregunta {
                               pregunta
                           }
                           respuesta {
                               childMarkdownRemark {
                                   html
                               }
                           }
                       }
                   }
               }
           }
       `;

export default Preguntas;
